import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Parent from "../components/parent"
import { graphql } from "gatsby"

const Parents = ({ data }) => {
  const parents = data.parents.edges[0].node.frontmatter.litters[0].parents

  return (
    <Layout>
      <SEO title="Meet the parents" />

      <section
        className="py-3"
        style={{ /*marginTop: 176,*/ backgroundColor: "#f5f5f5" }}
      >
        <div className="container mx-auto">
          <h1 className="display-8 text-center text-uppercase pb-3">
            Meet the parents
          </h1>
        </div>
        <div className="container">
          {parents.map(parent => (
            <Parent parent={parent} />
          ))}

          {/* <div className="row">
            <div className="col-sm-6">
              <Img fluid={data.toby.childImageSharp.fluid} />
            </div>
            <div className="col-sm-6">
              <h3
                className="display-5 text-center text-uppercase"
                style={{ color: "#cab683" }}
              >
                Toby (Dad)
              </h3>
              <hr />
              <p>
                Meet Toby! Toby is an F2 goldendoodle. He weighs 40lbs. Toby can
                hear anything from a mile away! He will alert his humans when he
                hears something out of the ordinary. He likes to observe human
                interactions. Toby loves to run! He will definetly out run his
                humans. Don't let his shyness fool you! Toby loves to play a
                good game of chase and get some belly rubs! Toby loves to keep
                his humans company by watching TV. He will happily watch a show
                with you!
              </p>
            </div>
          </div> */}
        </div>
      </section>
    </Layout>
  )
}

export default Parents

export const query = graphql`
  query {
    parents: allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            litters {
              parents {
                name
                description
                img {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                imageDirection
              }
            }
          }
        }
      }
    }
  }
`
