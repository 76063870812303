import React from "react"
import Img from "gatsby-image"

const Parent = ({ parent }) => {
  return (
    <div className="row pb-5">
      {parent.imageDirection === "left" && (
        <div className="col-sm-6">
          <Img fluid={parent.img.childImageSharp.fluid} />
        </div>
      )}
      <div className="col-sm-6">
        <h3
          className="display-5 text-center text-uppercase"
          style={{ color: "#cab683" }}
        >
          {parent.name}
        </h3>
        <hr />
        <p>{parent.description}</p>
      </div>

      {parent.imageDirection === "right" && (
        <div className="col-sm-6">
          <Img fluid={parent.img.childImageSharp.fluid} />
        </div>
      )}
    </div>
  )
}

export default Parent
